import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useParams, useNavigate } from 'react-router-dom';
import MainStats from './components/MainStats';
import PageContainer from './components/PageContainer';
import PlayerPage from './components/PlayerPage';
import { ThemeProvider, createTheme } from '@mui/material';
import TeamsPage from './components/TeamPage';
import { StatPlayType } from './helper';

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: 'Jost, Arial, sans-serif',
    },
  });
  
  const [timeframe, setTimeframe] = React.useState<StatPlayType | ''>(() => {
    const savedTimeframe = window.localStorage.getItem('timeframe') as StatPlayType | null;
    return savedTimeframe ?? StatPlayType.LAST_15;
  });
  const [activeTab, setActiveTab] = React.useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('css');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

  
  const handleSortChange = (event: any) => {
    setSortField(event.target.value as string);
  };

  const handleSortDirectionChange = () => {
    setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
  };


  
  return ( 
    <Router>
         <ThemeProvider theme={theme}>
          <div className="App">
            <PageContainer 
              onTeamPage={activeTab === 1} 
              activeTab={activeTab} 
              setActiveTab={setActiveTab} 
              timeframe={timeframe} 
              setTimeframe={setTimeframe}
              sortDirection={sortDirection}
              handleSortDirectionChange={handleSortDirectionChange}
              sortField={sortField}
              searchTerm={searchTerm}
              handleSearchChange={(event:any) => setSearchTerm(event.target.value)}
              handleSortChange={handleSortChange}
              >
              <Routes>
                <Route path="/" element={<MainStats timeframe={timeframe}/>} />
                <Route path="/player/:username" element={<PlayerPageWrapper />} />
                <Route path="teams" element={<TeamsPage  sortDirection={sortDirection} sortField={sortField} searchTerm={searchTerm} />} />
              </Routes>
            </PageContainer>
          </div>
      </ThemeProvider>
    </Router>
  );
}

const PlayerPageWrapper = () => {
  const { username } = useParams();
  return <div className="app_style_wrapper" ><PlayerPage username={username??''} /></div>;
};

export default App;
