import React, { useEffect, useState, useRef } from 'react';
import { useMutation, gql } from '@apollo/client';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import PlayerCellRenderer from '../PlayerCellRenderer';
import './styles.css';


interface Stat {
  playerName: string;
  username: string;
  rank: number;
  ci: number;
  ckdn: number;
  ckdnPercent: number;
  sk: number;
  _int: number;
  commPercent: number;
  intPercent: number;
  skPercent: number;
  comm: number;
  att: number;
  strkspercent: number;
  strks: number;
  lttPercent: number;
  ltt: number;
  optt: number;
  opttPercent: number;
  team: string;
  ng: number;
  pos: string;
  eng: number;
}

const GET_STATS_MUTATION = gql`
  mutation GetStats($timeframe: StatPlayType!) {
    getStats(timeframe: $timeframe) {
      stats {
        playerName
        username
        rank
        g
        ci
        ckdn
        ckdnPercent
        sk
        _int
        commPercent
        intPercent
        skPercent
        comm
        att
        strkspercent
        strks
        lttPercent
        ltt
        optt
        opttPercent
        team
        ng
        pos
        eng
      }
    }
  }
`;

interface MainStatsProps {
  timeframe: string;
}

const MainStats: React.FC<MainStatsProps> = ({  timeframe }) => {
  const [tableData, setTableData] = useState<Stat[]>([]);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 1000);
  const gridRef = useRef<any>(null);
  const widths:any = {
  'rank': isMobile ? 60 : 80,
  'playerName': isMobile ? 90 : 150,
   'team': isMobile ? 80 : 100,
  'ci': isMobile ? 60 : 80
  }
  const [getStats, { loading, error, data }] = useMutation(GET_STATS_MUTATION);

  const handleResize = () => {
    const mobile = window.innerWidth < 1000;
    setIsMobile(mobile);
    if (gridRef.current?.api) {
  
      // Get current column state
      const allColumnState = gridRef.current.api.getColumnState();
  
      // Update column properties to ensure specific columns are pinned
      const updatedColumnState = allColumnState.map((colState:any) => {
        if (['rank', 'playerName', 'team', 'ci'].includes(colState.colId)) {
          return { ...colState, pinned: 'left', width: widths[colState.colId] };
        }
        return colState;
      });
      gridRef.current.api.applyColumnState({
        state: updatedColumnState,
        applyOrder: true,
      });
    }
  };

 const playTypeMap = {
    'All Time': 'ALL_TIME',
    'Last 15': 'LAST_15',
    'No CSS': 'NO_CSS',
    };

  useEffect(() => {
    getStats({ variables: { timeframe: playTypeMap[timeframe as keyof typeof playTypeMap] } });
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getStats, timeframe]);

  useEffect(() => {
    if (data) {
      setTableData(data.getStats.stats);
    }
  }, [data]);

  const getColumnDefs = (isMobile: boolean) => [
    {
      headerName: 'Rank',
      field: 'rank' as keyof Stat,
      pinned: 'left' as 'left',
      lockPosition: 'left' as 'left',
      sortable: true,
      filter: !isMobile,
      width: widths['rank'],
      headerTooltip: 'Rank in the Crucible Leaderboard',
    },
    {
      headerName: 'Player',
      field: 'playerName' as keyof Stat,
      pinned: 'left' as 'left',
      lockPosition: 'left' as 'left',
      cellRenderer: PlayerCellRenderer,
      filter: !isMobile,
      width: widths['playerName'],
      headerTooltip: 'Players first initial, last name',
    },
    {
      headerName: 'Team',
      field: 'team' as keyof Stat,
      pinned: 'left' as 'left',
      lockPosition: 'left' as 'left',
      filter: !isMobile,
      width: widths['team'],
      headerTooltip: 'Players team',
    },
    {
      headerName: 'CSS',
      field: 'ci' as keyof Stat,
      pinned: 'left' as 'left',
      lockPosition: 'left' as 'left',
      filter: !isMobile,
      width: widths['ci'],
      headerTooltip: 'Players Crucible Intellect Score',
    },
    { headerName: 'Gms', field: 'g' as keyof Stat, filter: !isMobile },
    { headerName: 'Comm', field: 'comm' as keyof Stat, filter: !isMobile },
    { headerName: 'Att', field: 'att' as keyof Stat, filter: !isMobile },
    {
      headerName: 'Comm%',
      minWidth: !isMobile ? 130 : 70,
      width: !isMobile ? 130 : 70,
      field: 'commPercent' as keyof Stat,
      valueFormatter: (params: any) => `${params.value}%`,
      filter: !isMobile,
    },
    { headerName: 'Int', field: '_int' as keyof Stat, filter: !isMobile },
    {
      headerName: 'Int%',
      minWidth: !isMobile ? 130 : 70,
      width: !isMobile ? 130 : 70,
      field: 'intPercent' as keyof Stat,
      valueFormatter: (params: any) => `${params.value}%`,
      filter: !isMobile,
    },
    { headerName: 'Sk', field: 'sk' as keyof Stat, filter: !isMobile },
    { headerName: 'Sk%', filter: !isMobile,   field: 'skPercent' as keyof Stat,
      valueFormatter: (params: any) => `${params.value}%`, },
    { headerName: 'OptT', field: 'optt' as keyof Stat, filter: !isMobile },
    {
      headerName: 'OptT%',
      field: 'opttPercent' as keyof Stat,
      valueFormatter: (params: any) => `${params.value}%`,
      filter: !isMobile,
    },
    { headerName: 'LtT', field: 'ltt' as keyof Stat, filter: !isMobile },
    {
      headerName: 'LtT%',
      field: 'lttPercent' as keyof Stat,
      valueFormatter: (params: any) => `${params.value}%`,
      filter: !isMobile,
    },
    { headerName: 'CkDn', field: 'ckdn' as keyof Stat, filter: !isMobile },
    {
      headerName: 'CkDn%',
      field: 'ckdnPercent' as keyof Stat,
      valueFormatter: (params: any) => `${params.value}%`,
      filter: !isMobile,
    },
    { headerName: 'Strks', field: 'strks' as keyof Stat, filter: !isMobile },
    // {
    //   headerName: 'Strks%',
    //   field: 'strkspercent' as keyof Stat,
    //   valueFormatter: (params: any) => `${params.value}%`,
    //   filter: !isMobile,
    // },
  ];


  if (error) return <Typography color="error">Error: {error.message}</Typography>;

  return (
    <div className="main-stats">
          {loading && <div className="main_stats_loading_container">
          <CircularProgress style={{ color: '#1A283A' }} size={'100px'} />
        </div>}
      <div className="ag-theme-alpine custom-ag-grid loader-item" style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          ref={gridRef}
          className={`ag-theme-alpine${isMobile ? ' mobile_grid' : ''}`}
          columnDefs={getColumnDefs(isMobile)}
          rowData={tableData}
          loading={loading}
          defaultColDef={{
            flex: 1,
            minWidth: isMobile ? 60 : 120, // Dynamic minWidth based on parent width
            sortable: true,
            resizable: true,
             headerClass: 'ag-header-cell-label'
          }}
          tooltipShowDelay={0}
          suppressBrowserResizeObserver={true}
          suppressDragLeaveHidesColumns={true}
          suppressMovableColumns={true}
          onGridReady={handleResize}
          onColumnResized={handleResize}
        />
      </div>
    </div>
  );
};

export default MainStats;
