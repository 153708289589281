import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './styles.css';

const PlayerCellRenderer = (props: any) => {
  const navigate = useNavigate()
  return (
    <div
      onClick={() => {
        const username = props.data.username;
        navigate(`/player/${username}`);
      }}
      className="player-cell-item"
    >
      <Button className="player-cell-button">{props?.data?.playerName}</Button>
    </div>
  );
};

export default PlayerCellRenderer;
