import React, { useState, useEffect, useMemo } from 'react';
import { useMutation, gql } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { useTransition, animated } from 'react-spring';
import photo from './photo.png';
import './styles.css';
import { Tabs, Tab, Box } from '@mui/material';
import { ParentSize } from '@visx/responsive';
import { useNavigate } from 'react-router-dom';

const GET_TEAM_STATS_BY_ID = gql`
  mutation GetTeamStatsByID($teamID: String!) {
    getTeamStatsByID(teamID: $teamID) {
      teamName
      players {
        playerName
        playerNumber
        playerPosition
        css
        lastLogin
        int
        gamesPlayed
        gamesQuit
        username
      }
    }
  }
`;

const GET_TEAMS = gql`
  mutation GetTeams {
    getTeams {
      teams {
        id
        name
      }
    }
  }
`;

interface TeamsProps {
  sortDirection?: string;
  sortField?: any;
  searchTerm?: any;
}

const TeamsPage: React.FC<TeamsProps> = ({
  sortDirection,
  sortField,
  searchTerm,
}) => {
  const [teams, setTeams] = useState<any[]>([]);
  const [selectedTeamID, setSelectedTeamID] = useState<string | null>(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<any | null>(null);

  const navigate = useNavigate();

  const [getTeams] = useMutation(GET_TEAMS);
  const [getTeamStatsByID] = useMutation(GET_TEAM_STATS_BY_ID);

  const getStatsForTeam = async (teamID: string) => {
    try {
      setLoading(true);
      const { data } = await getTeamStatsByID({ variables: { teamID } });
      setData(data.getTeamStatsByID);
      setSelectedTabIndex(teams.findIndex((team) => team.id === teamID));
      setError(null)
      setTimeout(() => {
        setLoading(false)
      }, 500)
    } catch (error:any) {
      setLoading(false);
      setError(error)
      setError(`Error fetching team stats: ${error?.message}`);
    }
  };

  useEffect(() => {
    if (selectedTeamID) {
      setData(null)
      getStatsForTeam(selectedTeamID);
    }
  }, [selectedTeamID]);

  useEffect(() => {
    async function fetchTeams() {
      setLoading(true);
      try {
        const { data } = await getTeams();
        const teams = data?.getTeams?.teams ?? [];
        if (teams.length > 0) {
          const isFromTeam = JSON.parse(window.localStorage.getItem('navigatedFromTeam') ?? '{}');
          setTeams(teams);
          setSelectedTeamID(isFromTeam?.teamID || teams[0].id); 
          setError(null)
          window.localStorage.setItem('navigatedFromTeam', '{}');
        } else {
          setError('No teams found');
          setLoading(false)
        }
      } catch (error: any) {
        setError(`Error fetching teams: ${error.message}`);
        console.error('Error fetching teams:', error);
        setLoading(false);

      }
    }
    fetchTeams();
  }, [getTeams]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTabIndex(newValue);
    setSelectedTeamID(teams[newValue].id);
  };

  const playerData = data?.players ?? [];
  const teamName = data?.teamName ?? '';
  const filteredPlayers = playerData.filter((player: any) =>
    player.playerName.toLowerCase().includes(searchTerm.toLowerCase())
  );

 const sortedPlayers = useMemo(() => {
    return filteredPlayers.sort((a: any, b: any) => {
      if (!sortField) return 0;
      const isDateField = (field: any) => !isNaN(Date.parse(field));
      if (isDateField(a[sortField]) && isDateField(b[sortField])) {
        const dateA = new Date(a[sortField]);
        const dateB = new Date(b[sortField]);
        // @ts-ignore
        return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
      }
      if (sortDirection === 'asc') {
        return a[sortField] < b[sortField] ? -1 : 1;
      } else {
        return a[sortField] > b[sortField] ? -1 : 1;
      }
    });
  }, [filteredPlayers, sortField, sortDirection]);

  const transitions = useTransition(sortedPlayers, {
    keys: (player: any) => player.username,
    from: { opacity: 0, transform: 'translate3d(-100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-100%,0,0)' },
  });

  return (
    <div style={{ overflowY: 'hidden' }}>
      {loading  ? (
        <div className="teams_loading_container">
          <CircularProgress style={{ color: '#1A283A' }} size={'100px'} />
        </div>
      ) : null}
      <div>
        <ParentSize>
          {({ width }) => {
            const isMobile = width < 1000;
            return (
              <div className="margin-top-container">
                {!loading && error && <div style={{width: '100%', textAlign: 'center', marginTop: '80px'}}><Typography variant={isMobile ? 'h6' : 'h5'} color="error">{error}</Typography></div>}
                {!loading && !error && !playerData?.length  && <div style={{width: '100%', textAlign: 'center', marginTop: '80px'}}><Typography variant={isMobile ? 'h6' : 'h5'} color="error">{'No players on this team yet.'}</Typography></div>}
                <div className="teams-page" style={{overflowY: loading ? 'hidden' : undefined}}>
                  <div className="search-sort-bar">
                    <Tabs
                      value={selectedTabIndex}
                      onChange={handleTabChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="team tabs"
                      sx={{
                        '& .MuiTabs-indicator': { display: 'none' },
                        backgroundColor: 'white',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {teams.map((team, index) => (
                        <Tab
                          key={team.id}
                          label={team.name}
                          sx={{
                            backgroundColor: selectedTabIndex === index ? 'red' : 'white',
                            color: selectedTabIndex === index ? 'blue' : 'black',
                            transition: 'background-color 0.2s, color 0.2s',
                            '&.Mui-selected': {
                              color: 'white',
                            },
                            '&:hover':isMobile ? {} : {
                              backgroundColor: 'lightcoral',
                              color: 'white',
                            },
                            height: '48px',
                          }}
                        />
                      ))}
                    </Tabs>
                  </div>
                  <div className="players-list">
                    {transitions((style, player, t, index) => (
                      <animated.div style={style} key={player.username}>
                        <Card
                          elevation={5}
                          className="player-card-teams"
                          onClick={() => {
                            window.localStorage.setItem(
                              'navigatedFromTeam',
                              JSON.stringify({
                                player: player.username,
                                teamName,
                                teamID: selectedTeamID,
                              })
                            );
                            navigate(`/player/${player.username}`);
                          }}
                        >
                          <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                            <Avatar
                              sx={{ '& .MuiAvatar-img': { objectFit: 'cover' } }}
                              src={photo}
                              className="player-avatar"
                            />
                            <div className="right_chips">
                              <Typography
                                variant={isMobile ? 'h6' : 'h4'}
                                component="div"
                                style={{ position: 'relative', right: '35px', fontWeight: 'bold' }}
                              >
                                {player.playerName}
                              </Typography>
                              <div className="player-info-teams">
                                <Chip className="flex_chip_1" label={`# ${player.playerNumber}`} />
                                <Chip className="flex_chip_2" label={teamName} />
                                <Chip className="flex_chip_1" label={player.playerPosition} />
                                <Chip className="flex_chip_2" label={`CSS: ${player.css}`} />
                              </div>
                            </div>
                          </div>
                          <div className="mid_bar_item">Offense / Defense Play Grades</div>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className="player-info-teams">
                              <Chip
                                className="flex_chip"
                                label={`${isMobile ? 'Active' : 'Last Played'}: ${!player.lastLogin ? '-' : new Date(player.lastLogin).toLocaleDateString()}`}
                              />
                              <Chip className="flex_chip" label={`INT: ${player.int}`} />
                              <Chip className="flex_chip" label={`Games Played: ${player.gamesPlayed}`} />
                              <Chip className="flex_chip" label={`Games Quit: ${player.gamesQuit}`} />
                            </div>
                          </div>
                        </Card>
                      </animated.div>
                    ))}
                  </div>
                </div>
              </div>
            );
          }}
        </ParentSize>
      </div>
    </div>
  );
};

export default TeamsPage;
